<div class="my-container">
  <div class="menu-hours">
    <mat-card>
      <mat-card-title>
        <img src="./assets/alarm-24px.svg">
        Nos horaires d'hiver
      </mat-card-title>
      <mat-card-content>
        <div class="group-hours">
          <div class="item-hour"> du 1er décembre au 31 mars</div>
          <div class="item-hour"> ouvert 7/7 pendant les vacances scolaires</div>
          <div class="item-hour-end"> de 8h00 à 00h00</div>
            <div class="item-hour-end"> Services de 11h30 à 15h et de 19h à 22h30</div>

          <div class="item-hour">Hors vacances scolaires: fermé le jeudi</div>
<!--          <mat-divider></mat-divider>-->
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="sub-menu" (click)="onDown('total')">
    <div>
      <img src="./assets/restaurant_menu-24px.svg"/>
      Télécharger la carte
    </div>
  </div>
  <div class="container-img-menu">
    <div *ngFor="let menu of imgMenus" class="img-menu" id="{{menu.title}}">
      <img src="{{menu.img}}" class="img-size-menu" (click)="onDown(menu)">
    </div>
  </div>
</div>
