import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './home/home.component';
import { AppWrapperComponent } from './app-wrapper/app-wrapper.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { AppContentComponent } from './app-content/app-content.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {DemoMaterialModule} from './material-module';
import { NgImageSliderModule } from 'ng-image-slider';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import { MenuComponent } from './menu/menu.component';
import { ReservationComponent } from './reservation/reservation.component';
import { FileSaverModule } from 'ngx-filesaver';
import { CarteEteComponent } from './carte-ete/carte-ete.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FacebookModule } from 'ngx-facebook';

export const appRoutes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {path: 'home', component: HomeComponent},
  {path: 'menu', component: MenuComponent},
  {path: 'menuete', component: CarteEteComponent},
  {path: 'reservation', component: ReservationComponent},
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AppWrapperComponent,
    AppHeaderComponent,
    AppFooterComponent,
    AppContentComponent,
    SpinnerComponent,
    MenuComponent,
    ReservationComponent,
    CarteEteComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(
      appRoutes,
      // {onSameUrlNavigation: 'reload'}
    ),
    NgbModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    NgImageSliderModule,
    LeafletModule,
    FileSaverModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    FacebookModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
