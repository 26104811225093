<div class="my-container">
  <mat-card class="my-card">
    <mat-card-title>
      Formulaire de contact
    </mat-card-title>
    <mat-card-subtitle>
      N'hésitez pas à nous envoyer un message pour réserver ou poser vos questions
    </mat-card-subtitle>
    <mat-card-content>
      <form autocomplete="on" [formGroup]="myForm" (ngSubmit)="onSubmit()">
        <div class="form-flex">
          <mat-form-field appearance="legacy">
            <mat-label>Prénom</mat-label>
            <input matInput placeholder="Prénom" formControlName="firstname">
            <mat-icon matSuffix>edit</mat-icon>
            <!--            <mat-hint>Hint</mat-hint>-->
          </mat-form-field>
          <mat-form-field appearance="legacy">
            <mat-label>Nom</mat-label>
            <input matInput placeholder="Nom" formControlName="lastname">
            <mat-icon matSuffix>edit</mat-icon>
            <!--            <mat-hint>Hint</mat-hint>-->
          </mat-form-field>

          <mat-form-field appearance="legacy">
            <mat-label>Téléphone</mat-label>
            <input matInput placeholder="Téléphone" formControlName="phone">
            <mat-icon matSuffix>local_phone</mat-icon>
            <!--            <mat-hint>Hint</mat-hint>-->
          </mat-form-field>
          <mat-form-field appearance="legacy">
            <mat-label>Mail</mat-label>
            <input matInput placeholder="Email" #email formControlName="mail">
            <mat-icon matSuffix>alternate_email</mat-icon>
            <!--            <mat-hint>Hint</mat-hint>-->
          </mat-form-field>
        </div>
        <div class="form-flex">
          <mat-form-field appearance="fill" class="text-area" >
            <mat-label>Votre message</mat-label>
            <mat-icon class="icon-content">text_sms</mat-icon>
            <textarea matInput rows="10" formControlName="content">
            </textarea>

          </mat-form-field>
        </div>

        <div class="form-flex">
          <button mat-raised-button type="submit" [disabled]="!myForm.valid" [ngClass]="!myForm.valid?'submit-invalid':'submit-valid'">
            <mat-icon matPrefix>send</mat-icon>
            Envoyer
          </button>
        </div>

      </form>


    </mat-card-content>
  </mat-card>
</div>
