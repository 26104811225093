<header>
  <div class="my-container">
    <div class="logo">
      <img src="./assets/logo-transparent.png">
    </div>
    <div class="header-navigation">
      <div>
        <img src="./assets/titre-resto.png" class="adaptativ-img">
      </div>
      <div class="navigation">
        <span class="link-nav">
      <a (click)="routeNavigate('home')">
          Accueil</a>
        </span>
        <span class="link-nav">
           <a (click)="routeNavigate('menu')">
          Hiver
            </a>
        </span>
        <span class="link-nav">
           <a (click)="routeNavigate('menuete')">
          Eté
            </a>
        </span>
        <span class="link-nav">
            <a (click)="routeNavigate('reservation')">
          Réservation
            </a>
        </span>
      </div>
    </div>
    <div class="social">
      <!--      <img src="./assets/logo-facebook.png" class="img-logo-facebook">-->
    </div>
  </div>


</header>

