<div class="my-container">
  <div class="menu-hours">
    <mat-card>
      <mat-card-title>
        <img src="./assets/alarm-24px.svg">
        Nos horaires d'été
      </mat-card-title>
      <mat-card-content>
        <div class="group-hours">
          <div class="item-hour"> du 15 juin au 15 septembre</div>
          <div class="item-hour-end"> ouvert de 9h00 à 23h00, 7 jours/7</div>
          <div class="item-hour-end"> Services de 12h00 à 14h et de 19h à 21h30</div>

<!--          <div class="item-hour">Fermé le jeudi</div>-->
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="sub-menu" (click)="onDown('total')">
    <div>
      <img src="./assets/restaurant_menu-24px.svg"/>
      Télécharger la carte
    </div>
  </div>
  <div class="container-img-menu">
    <div *ngFor="let menu of imgMenus" class="img-menu" id="{{menu.title}}">
      <img src="{{menu.img}}" class="img-size-menu" (click)="onDown(menu)">
    </div>
  </div>
</div>
