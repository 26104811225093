import { Component, OnDestroy, OnInit } from '@angular/core';
import { Events } from '../services/events.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit, OnDestroy {

  show = false;
  spin = null;

  constructor(
    private events: Events
  ) {
  }

  ngOnInit(): void {
    const spin = (arg: { spin: boolean }) => {
      this.show = arg.spin;
    };
    this.spin = spin;
    this.events.subscribe('spinner', spin);
  }

  ngOnDestroy(): void {
    this.events.unsubscribe('spinner', this.spin);
  }

}
