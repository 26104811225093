<div class="container">
  <div class="my-flex">


    <div class="slider">
      <ng-image-slider #nav
                       [images]="imageObject"
                       [infinite]="true"
                       [autoSlide]="3"
                       [animationSpeed]="3"
                       [imageSize]="{width: '250px', height:'250px'}"
                       slideImage="1"></ng-image-slider>
    </div>
    <div>
      <br>
      <h2 class="title-welcome"> Le restaurant s'invite chez vous ! <br><br></h2>
     <!-- <p>
        Nous nous adaptons et nous vous proposons une carte spéciale <b>"A emporter"</b>, en respectant bien sûr toutes
        les règles sanitaires.
      </p>

      <div class="horaires-center">
        Nous serons heureux de vous accueillir tous les jours de
        <div class="horaires-center-lorem">9h à 14h</div>
        <div class="horaires-center2-lorem">15h30 à 18h</div>
      </div>

      <div class="phrase">
        En attendant de vous accueillir à nouveau dans notre restaurant, quand la situation le permettra.
      </div>-->
    </div>
    <div>
      <div>
        <mat-card class="hours-card">
          <mat-card-title>
            <img src="./assets/room_service-24px.svg">
            Carte "A emporter"
          </mat-card-title>
          <mat-card-content>
            <div class="my-flex-emporter">
              <div class="entity-emporter">
                <span class="title-rubric-emporter">Plats</span>
                <mat-list>
                  <mat-list-item> Planche charcuterie<span class="align-right">14,00 €</span></mat-list-item>
                  <mat-list-item> Planche montagnarde<span class="align-right">16,00 €</span></mat-list-item>
                  <mat-list-item> Tartiflette<span class="align-right">15,00 €</span></mat-list-item>
                  <mat-list-item> Plateau raclette<span class="align-right">18,00 €</span></mat-list-item>
                  <mat-list-item><span class="align-right-raclette">35,00 € /2 pers.</span></mat-list-item>
                  <mat-list-item class="last-element"> Plat du jour : voir ardoise</mat-list-item>
                </mat-list>
              </div>
              <div class="entity-emporter">
                <span class="title-rubric-emporter">Pizzas</span>
                <mat-list>
                  <mat-list-item> Alpine<span class="align-right">14,00 €</span></mat-list-item>
                  <span class="ingredients">
                    crème, jambon, pommes de terre, raclette
                  </span>
                  <mat-list-item> Reblochon<span class="align-right">13,50 €</span></mat-list-item>
                  <span class="ingredients">
                  crème, reblochon, pommes de terre, oignons, lardons, emmental
                  </span>
                  <mat-list-item> Campagnarde<span class="align-right">13,00 €</span></mat-list-item>
                  <span class="ingredients">
                  tomate, emmental, oignons, lardons, crème
                  </span>
                  <mat-list-item> Royale<span class="align-right">13,00 €</span></mat-list-item>
                  <span class="ingredients">
                  tomate, emmental, jambon, champignons
                  </span>
                  <mat-list-item> Fromage<span class="align-right">11,00 €</span></mat-list-item>
                  <span class="ingredients">
                  tomate, emmental
                  </span>
                </mat-list>
              </div>
<!--              <div class="entity-emporter">
                <span class="title-rubric-emporter">Desserts</span>
                <mat-list>
                  <mat-list-item> Glace<span class="align-right">1 boule 2,00 €</span></mat-list-item>
                  <mat-list-item><span class="align-right">2 boules 3,50 €</span></mat-list-item>
                  <mat-list-item><span class="align-right">3 boules 5,00 €</span></mat-list-item>
                  <mat-list-item> Crêpe<span class="align-right">3,50 €</span></mat-list-item>
                  <mat-list-item> Gaufre<span class="align-right">4,50 €</span></mat-list-item>
                  <mat-list-item> Dessert du jour : voir ardoise</mat-list-item>
                </mat-list>
              </div>-->
       <!--       <div class="entity-emporter">
                <span class="title-rubric-emporter">Boissons</span>
                <mat-list>
                  <mat-list-item> Soda<span class="align-right">2,50 €</span></mat-list-item>
                  <mat-list-item>Café <span class="align-right">1,80 €</span></mat-list-item>
                  <mat-list-item>Chocolat <span class="align-right">2,50 €</span></mat-list-item>
                  <mat-list-item> Thé<span class="align-right">2,50 €</span></mat-list-item>
                  <mat-list-item> Vin : voir ardoise</mat-list-item>
                </mat-list>
              </div>-->
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="mat-cards">
      <div>
        <mat-card class="hours-card">
          <mat-card-title>
            <img src="./assets/alarm-24px.svg">
            "A emporter"
          </mat-card-title>
          <mat-card-content>
            <div class="horaires-center">
              <div class="horaires-center2">
                Retrouvez nous tous les jours de
              </div>
              <div class="horaires-center2">11h à 21h</div>
           <!--   <div class="horaires-center2">et</div>
              <div class="horaires-center2">15h30 à 18h.</div>-->
              <br>
              <div class="horaires-center3">
                Pensez à réserver...
              </div>
            </div>

          </mat-card-content>
        </mat-card>
        <mat-card class="hours-card">
          <mat-card-title>
            <img src="./assets/event-24px.svg">
            Hors saison
          </mat-card-title>
          <mat-card-content>
            <div class="horaires-center">

              <div class="horaires-center2">
                Traiteur, évenementiel,
              </div>
              <div class="horaires-center2">
                privatisation restaurant.....
              </div>
              <div class="horaires-center3">
                Contactez-nous !
              </div>
            </div>

          </mat-card-content>
        </mat-card>
      </div>
      <div>
        <mat-card class="map-card">
          <mat-card-title>
            <img src="./assets/location_on-24px.svg">
            Nous trouver
          </mat-card-title>
          <mat-card-content>
            <div id="map"
                 leaflet
                 (leafletMapReady)="onMapReady($event)"
                 [leafletOptions]="mapOptions">
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div>
        <mat-card class="hours-card">
          <mat-card-title>
            <img src="./assets/contacts-24px.svg">
            Nous contacter
          </mat-card-title>
          <mat-card-content>
            <br>
            <table>
              <tr *ngFor="let contact of contacts">
                <td class="icon"><img src="{{contact.icon}}"></td>
                <td *ngIf="!contact.link">{{contact.content}}</td>
                <td *ngIf="contact.link"><a href="{{contact.link}}" target="_blank"> {{contact.content}} </a></td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>
    <!--  <div>
        <mat-card class="hours-card">
          <mat-card-title>
            <img src="./assets/event-24px.svg">
           Hors saison
          </mat-card-title>
          <mat-card-content>
            <div class="horaires-center">

              <div class="phrase">
                Traiteur, évenementiel, privatisation restaurant.....
              </div>
              <div class="horaires-center3">
                Contactez-nous !
              </div>
            </div>

          </mat-card-content>
        </mat-card>
      </div>-->
    </div>
  </div>
</div>
