import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';


@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.css']
})
export class ReservationComponent implements OnInit {

  contact = {
    firstname: '',
    lastname: '',
    mail: '',
    phone: ''
  };
  myForm = new FormGroup({
    firstname: new FormControl('', [
      Validators.required
    ]),
    lastname: new FormControl('', [
      Validators.required,
      Validators.minLength(2)
    ]),
    phone: new FormControl('', [
      Validators.required,
      Validators.minLength(9),
      Validators.pattern('((?:\\+|00)[17](?: |\\-)?|(?:\\+|00)[1-9]\\d{0,2}(?: |\\-)?|(?:\\+|00)1\\-\\d{3}(?: |\\-)?)?(0\\d|\\([0-9]{3}\\)|[1-9]{0,3})(?:((?: |\\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\\-)[0-9]{3}(?: |\\-)[0-9]{4})|([0-9]{7}))')
    ]),
    mail: new FormControl('', [
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
    ]),
    content: new FormControl('', [
      Validators.required,
    ]),
  });

  constructor(private httpClient: HttpClient, private router: Router) {
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    const options = {
      headers: {
        Authorization: '16545eqr1ba5465a45jjkjkljkl6a454&7534165&4461'
      }
    };
    const body = {
      nom: this.myForm.value.lastname,
      prenom: this.myForm.value.firstname,
      phone: this.myForm.value.phone,
      mail: this.myForm.value.mail,
      content: this.myForm.value.content,
    };

    this.httpClient.post('/mail', body, options).subscribe(response => {
      console.log(response);
      this.router.navigateByUrl('/home');
    });

    // Do somthing
    // You can get the values in the forms like this: this.myForm.value
  }

}
