import { Component, OnInit } from '@angular/core';
import * as FileSaver from 'file-saver';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  imgMenus = [
    {title: 'Plats', img: './assets/menu/plats-1.jpg'},
    {title: 'Pizzas', img: './assets/menu/pizzas-1.jpg', pdf: './assets/pdf/pizzas.pdf'},
    {title: 'Desserts', img: './assets/menu/desserts-1.jpg', pdf: './assets/pdf/desserts.pdf'},
    {title: 'Glaces', img: './assets/menu/glaces-1.jpg', pdf: './assets/pdf/glaces.pdf'},
    {title: 'Boissons', img: './assets/menu/boissons-1.jpg', pdf: './assets/pdf/boissons.pdf'}
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

  onDown(menu) {
    const fileName = `les-cimes-${menu.title}.pdf`;
    switch (menu.title) {
      case 'Boissons':
        FileSaver.saveAs('./assets/pdf/boissons.pdf', fileName);
        break;
      case 'Plats':
        FileSaver.saveAs('./assets/pdf/plats.pdf',  fileName);
        break;
      case 'Pizzas':
        FileSaver.saveAs('./assets/pdf/pizzas.pdf', fileName);
        break;
      case 'Desserts':
        FileSaver.saveAs('./assets/pdf/desserts.pdf', fileName);
        break;
      case 'Glaces':
        FileSaver.saveAs('./assets/pdf/glaces.pdf', fileName);
        break;
      default:
        FileSaver.saveAs('./assets/pdf/carte-for-web-compress.pdf', 'Les-Cimes-Menu.pdf');
    }
  }
}
