import { Component, OnInit } from '@angular/core';
import { icon, latLng, Map, MapOptions, Marker, tileLayer } from 'leaflet';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  imageObject = [{
    image: './assets/photos/bar1.jpg',
    thumbImage: './assets/photos/bar1-th.jpg',
  }, {
    image: './assets/photos/bar2.jpg',
    thumbImage: './assets/photos/bar2-th.jpg'
  },
    {
      image: './assets/photos/terrasse.jpg',
      thumbImage: './assets/photos/terrasse-th.jpg'
    },
    {
      image: './assets/photos/assiette1.jpg',
      thumbImage: './assets/photos/assiette1-th.jpg',
      // title: 'Example with title.'
    }, {
      image: './assets/photos/salle1.jpg',
      thumbImage: './assets/photos/salle1-th.jpg'
    }, {
      image: './assets/photos/salle2.jpg',
      thumbImage: './assets/photos/salle2-th.jpg',
      // title: 'Hummingbirds are amazing creatures'
    }
  ];

  contacts = [
    {icon: './assets/house-24px.svg', content: 'Les Cimes Chabanon 04140 Selonnet'},
    {icon: './assets/phone-24px.svg', content: '+33 4 92 35 26 37'},
    // {icon: './assets/smartphone-24px.svg', content: '+33 6 01 02 02 02'},
    {icon: './assets/email-24px.svg', content: 'lescimes04@gmail.com'},
    {icon: './assets/gps_fixed-24px.svg', content: '44.34742869856267 6.294000897823409'},
    {icon: './assets/facebook-24px.svg', content: 'Rejoignez-nous !', link: 'https://www.facebook.com/lescimes04'},
    {icon: './assets/instagram-logo.jpg', content: 'Instagrammez !', link: 'https://https://www.instagram.com/lescimes04/'},
  ];

  map: Map;
  mapOptions: MapOptions;

  constructor() {
  }

  ngOnInit(): void {
    this.initializeMapOptions();
  }

   onMapReady(map: Map) {
    this.map = map;
    this.addSampleMarker();
  }

  private initializeMapOptions() {
    this.mapOptions = {

      center: latLng(44.34742869856267, 6.294000897823409),
      zoom: 12,
      layers: [
        tileLayer(
          'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          {
            maxZoom: 20,
            attribution: 'Map data © OpenStreetMap contributors'
          })
      ],
    };
  }

  private addSampleMarker() {
    const marker = new Marker([44.34742869856267, 6.294000897823409])
      .setIcon(
        icon({
          iconSize: [25, 41],
          iconAnchor: [0, 0],
          iconUrl: './assets/marker-icon-2x.png'
        }));
    marker.addTo(this.map);
  }

  testImage() {

    // {width: '25%', height: '25%'}
    return '33';
  }

}
