<div class="footer-container" >
  <footer class="container">
    <div class="webfooter">
      <ul>
        <li class="pull-left">© Les Cîmes 2021</li>
        <li class="pull-right">version {{version}}</li>
      </ul>
    </div>
  </footer>
</div>
