import { Component, OnInit } from '@angular/core';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-carte-ete',
  templateUrl: './carte-ete.component.html',
  styleUrls: ['./carte-ete.component.css']
})
export class CarteEteComponent implements OnInit {
  imgMenus = [
    {title: 'Pizzas', img: './assets/menu/pizzas-1.jpg', pdf: './assets/pdf/pizzas.pdf'},
    {title: 'Plats', img: './assets/menu/plats-ete.jpg', pdf: './assets/pdf/plats-ete.pdf'},
    {title: 'Glaces', img: './assets/menu/glaces-1.jpg', pdf: './assets/pdf/glaces.pdf'},
    {title: 'Boissons', img: './assets/menu/boissons-1.jpg', pdf: './assets/pdf/boissons.pdf'}
  ];
  constructor() { }

  ngOnInit(): void {
  }
  onDown(menu) {
    const fileName = `les-cimes-${menu.title}.pdf`;
    switch (menu.title) {
      case 'Boissons':
        FileSaver.saveAs('./assets/pdf/boissons.pdf', fileName);
        break;
      case 'Plats':
        FileSaver.saveAs('./assets/pdf/plats-ete.pdf',  fileName);
        break;
      case 'Pizzas':
        FileSaver.saveAs('./assets/pdf/pizzas.pdf', fileName);
        break;
      case 'Glaces':
        FileSaver.saveAs('./assets/pdf/glaces.pdf', fileName);
        break;
      default:
        FileSaver.saveAs('./assets/pdf/LesCimes04-Carte-ete.pdf', 'Les-Cimes04-Menu-ete.pdf');
    }
  }
}
