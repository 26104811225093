import { Component, OnInit } from '@angular/core';
import { FacebookService, InitParams } from 'ngx-facebook';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
// export class AppComponent implements OnInit {
export class AppComponent  {
  title = 'les-cimes';

/*
  constructor(private facebookService: FacebookService) {
  }

  ngOnInit(): void {
    // setTimeout(function() {
      this.initFacebookService();
    // }, 1000);
  }
*/

/*  private initFacebookService(): void {
    const initParams: InitParams = {xfbml: true, version: 'v3.2'};
    this.facebookService.init(initParams);
  }*/

}
